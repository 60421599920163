import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/BlogLayout/index.jsx";
import BlogPostMetaData from "../../../../components/BlogPostMetaData";
import TCCOutboundLink from "../../../../components/TCCOutboundLink";
export const pageMetaData = {
  pageTitle: "An Introduction To Cryptocurrencies",
  pageStrapLine: "Understanding Cryptocurrencies",
  pageImagePath: "/2021-03-02-an-introduction-to-cryptocurrency.jpg",
  pageDescription: "Find out what cryptocurrencies are, what you can do with them and why everyone is talking about them",
  pageMetaTags: []
};
export const _frontmatter = {};
const layoutProps = {
  pageMetaData,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`An Introduction To Cryptocurrencies`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "720px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b708fc338f18e12a92f239366b042173/80e3c/2021-03-02-an-introduction-to-cryptocurrency.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.66666666666666%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMBAgQF/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQAC/9oADAMBAAIQAxAAAAHlPzWyyKG//8QAGBABAQEBAQAAAAAAAAAAAAAAAQACETH/2gAIAQEAAQUCPd4c3IZXU3//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAYEAADAQEAAAAAAAAAAAAAAAABECEAEf/aAAgBAQAGPwLg1LpX/8QAGxABAAICAwAAAAAAAAAAAAAAAQARIVExQXH/2gAIAQEAAT8hGHZKFDUbO4g2YYrmfZa+Z//aAAwDAQACAAMAAAAQPA//xAAVEQEBAAAAAAAAAAAAAAAAAAAQIf/aAAgBAwEBPxCH/8QAFhEAAwAAAAAAAAAAAAAAAAAAEBEh/9oACAECAQE/EKx//8QAGhABAQADAQEAAAAAAAAAAAAAAREAITFhQf/aAAgBAQABPxBmiqAPcGG10LR9sxOhGrgBIGk6TBijtqvudH1vP//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "An Introduction To Cryptocurrencies",
            "title": "An Introduction To Cryptocurrencies",
            "src": "/static/b708fc338f18e12a92f239366b042173/80e3c/2021-03-02-an-introduction-to-cryptocurrency.jpg",
            "srcSet": ["/static/b708fc338f18e12a92f239366b042173/f93b5/2021-03-02-an-introduction-to-cryptocurrency.jpg 300w", "/static/b708fc338f18e12a92f239366b042173/b4294/2021-03-02-an-introduction-to-cryptocurrency.jpg 600w", "/static/b708fc338f18e12a92f239366b042173/80e3c/2021-03-02-an-introduction-to-cryptocurrency.jpg 720w"],
            "sizes": "(max-width: 720px) 100vw, 720px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <BlogPostMetaData postDate={props.pageContext.frontmatter.date} category={props.pageContext.frontmatter.blogCategory} mdxType="BlogPostMetaData" />
    <p>{`Cryptocurrency (or Crypto) is a term and subject that has acquired immense popularity over the past ten years. Gaining traction across social media forums, and even making headway in our international news circuits.`}</p>
    <p>{`Everyone is talking about it.`}</p>
    <p>{`But not all of us understand what it is exactly. Or where it comes from, and what words like “blockchain” and “bitcoin” even mean.`}</p>
    <p>{`In this article, I will try to explain to newcomers in the world of cryptocurrencies.`}</p>
    <h2>{`What is it?`}</h2>
    <p>{`We'll start this journey by looking at what a cryptocurrency is and the underlying terminology behind it.`}</p>
    <p>{`Cryptocurrency in general is a type of digital money. Created to work as a medium of exchange. This means that you can use it to trade goods and services instead of using cash. It was invented by a person or a group of persons under the name ‘Satoshi Nakamoto’ back in 2009.`}</p>
    <p>{`The first thing to know is that there are several different types of cryptocurrencies out there today. Some of them include Ethereum, Altcoin, and Bitcoin. Although it was Bitcoin that paved the way for cryptocurrency as a whole.`}</p>
    <p>{`This digital currency is both created and exchanged independently of any central banking system. Bitcoin being originally invented to take away the middleman/bank and other government-run currency systems.`}</p>
    <h2>{`How does it work?`}</h2>
    <p>{`Crypto works as a whole by using a technology called “blockchain”. Which is a decentralized tech that spreads across several computers. Managing to record transactions, while doing so in an appealingly secure way. The best way to view it is almost like casino chips, or tickets, that you use to exchange for real currency. Except you gain these “chips or tickets” by data mining.`}</p>
    <p>{`Some of the top cryptocurrencies on the market, which are publicly traded. Are Bitcoin, coming in at a Market Capitalization of $563.8 billion. Followed up by Ethereum at $142.9 billion, Tether at $25.2 billion, and Polkadot at $13.9 billion. These companies are all tracked by CoinMarketCap, which is a cryptocurrency data and analytics company.
Even though I only listed a handful of publicly traded companies. There are thousands of companies in existence today who participate in the cryptocurrency movement. After bitcoin was introduced, it has done nothing but take-off as an industry at a breakneck pace.`}</p>
    <h2>{`Related terminologies`}</h2>
    <p>{`Some of the terminologies behind crypto can leave people confused at times. Although it's not as overly technical as some can be intimidated into believing.`}</p>
    <p>{`The biggest one was blockchain, which is the foundational tech to cryptocurrency. It can provide an immutable, digital audit trail of transactions, and can be used to cheaply verify the integrity of data. Taking away the costly intermediary.`}</p>
    <p>{`However for those who are wanting to get into mining and trading cryptocurrency. Acronyms and terminology such as HOLM, FOMO, DYOR, Sats, and REKT can leave even the most experienced at business, scratching their heads.`}</p>
    <p>{`HOLM ultimately means “holding onto your currency instead of selling it.” Which has folklore origins of coming from a drunk guy on an online forum who went on an intoxicated rant. Resulting in the misspelt acronym that is now used in times of market volatility. Referring to individuals who are holding their crypto, when others are not.`}</p>
    <p>{`FOMO is the “Fear of missing out”, describing “fear traders”, referring to the feeling of missing out on gains others could be acquiring. This mindset is usually amplified by the crazy price swings crypto has been known to have. Often those who fall victim to the FOMO mindset are doing so irrationally. Letting social media fuel their research, instead of actual statistical research.`}</p>
    <p>{`Instead, adopt the mentality of, DYOR, or “Do your own research”. The FOMO mindset new traders or even bitcoin maximalists might adopt is not recommended for the business conscious. Instead stick to trading analysis, charts, and statistics provided by verified market caps.`}</p>
    <p>{`Sats is short for Satoshi, or Satoshi Nakamoto. Who is the supposed creator of Bitcoin and the mysterious potential Father of Cryptocurrency? To say you gave someone Sats, is to say you gave them a small portion of a BTC. Or instead of saying I gave someone 0.0001 BTC, you would say I gave someone 10,000 Satoshis or Sats.`}</p>
    <p>{`Finally, you have REKT, which originated as a gamer term. While also being slang for the actual work “wrecked”. Someone who is in the crypto community might use this to describe a financial loss from poor market judgment, or a series of unfortunate market moves.`}</p>
    <h2>{`Wrapping up`}</h2>
    <p>{`All in all, this is simply touching the tip of the iceberg when diving into the world of Cryptocurrency. When speaking on the subject today you will be met with a lot of different information and variations of support. While some advocates love that Crypto removes central banks from managing the money supply. Others will argue that though Cryptocurrencies may be projected as the future of currency. Currency as a whole still needs trackable stability.`}</p>
    <p>{`Ultimately, however, it's about what you know, and staying up to date with the current market trends.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      